@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: "Poppins", sans-serif;   
}

:root{
    --color1: #149A9B;
    --color2: #ffffff;
    --color3: #000;
    --color4: #EEEEEE;
    
}
nav a,
footer a,
.stories-page a,
.service-page a,
.sub-category a,
.contact-page a,
.sidebar a,
.product-page a,
.product-detail a{
    color: unset;
    text-decoration: none;
}
.recent-blog a,
.story-cover{
  text-decoration: none;
}
.story-cover.read-text{
  color: var(--color1);
}
nav li,
footer li{
    list-style-type: none;
}
nav ul li a{
  font-size: 14px;
}
nav ul li{
  position: relative;
}
nav .dropdown-item li:after{
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 1.2px;
  height: 100%;
  background-color: rgb(193, 193, 193);
}
nav ul li a:hover{
  color: var(--color1);
  border-bottom: 2px solid var(--color1);
}
nav ul li .active{
    color: var(--color1);
    border-bottom: 2px solid var(--color1);
}
nav ul .contact-btn a{
    background-color: var(--color1);
}
.search-form{
  height: 40px;
}
.dropdown-item{
  top:  100%;
  width: 100%;
  border-top: 4px solid var(--color1) !important;
  left: 0%;
  z-index: 10000;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.dropdown-item-2{
 top: 0%;
  right: 0%;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10000;
  background-color: var(--color2);
  
}
.dropdown-item-2 li a{
  font-size: 14px;
  
}
.dropdown-item-2 li{
  position: relative !important;
}
.sidebar{
  position: fixed;
  right: 0%;
  top: 0%;
  z-index: 2;
  height: 100vh;
  width: 280px;
  background-color: var(--color2);
  padding-top: 80px;
  margin-right: -380px;
 transition: all 0.4s ease;
 overflow-y: auto;
}
.sidebar.show{
  margin-right: 0px;
}
.sidebar li a:hover{
  color: var(--color1);
  border-bottom: 2px solid var(--color1);
}
.sidebar li a.active{
  color: var(--color1);
  border-bottom: 2px solid var(--color1);
}
.banner,
.banner .lazy-load-image-background{
    height: 580px;
   
}
.banner img{
    width: 100% !important;
    height: 100%;
  object-fit: cover;
  
}
.lazy-load-image-background{
  display: block !important;
}
.banner::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 0.8));
}
.banner-content{
    right: 0%;
    z-index: 1;
    top: 45%;
    width: 40%;
}
.banner-content h1{
    letter-spacing: 1px;
    font-size: 46px;
    
}
.banner-content h1:last-of-type{
    font-size: 54px;
    font-weight: 700;
    letter-spacing: 3px;
}
.read-more{
  color: var(--color2);
  backdrop-filter: blur(20px);
}
.read-more:hover{
   color: #0ed5d5;
   border-bottom: 2px solid var(--color1);
}
.stories-section{
    background-color: var(--color4);
    
}

.heading span{
    content: "";
    position: absolute;
    min-width: 100px;
    height: 3px;
    background-color: var(--color1);
    bottom: 0;
    left: 0;
}
.read-text{
    color: var(--color1);
border: none;
}
.read-text:hover{
  border-bottom: 2px solid var(--color1);
  color: #066d6d;
}
h1.title-text{
  font-size: 1.6rem;
  color: var(--color1);
}
.tags-list{
  border: 1px solid black;
}
.tags-list:hover{
  background-color: var(--color1);
  color: var(--color2);
  border: 1px solid var(--color1);
}
.tags-list.active{
  background-color: var(--color1);
  color: var(--color2);
  border: 1px solid var(--color1);
}
.swiper {
    width: 100%;
    height: 100%;
  }
  .card-box img{
    height: 260px;
  }
  .service-box img{
    height: 200px;
  }
  .service-box .hover-card{
    background-color: rgb(8, 156, 156, 0.7);
    transform: translateY(100%);
    opacity: 0;
    transition: all 0.5s ease;
   
  }
  .service-box .card-img:hover .hover-card{
    transform: translateY(0%);
    opacity: 1;
  }
  .service-box .card-body{
    height: 180px;
  }
  .text-sm{
    font-size: 14px;
    text-align: justify;
  }
  .support-btn{
    border: 1.5px solid var(--color1);
    color: var(--color1);
  }
  .support-btn:hover{
   background-color: var(--color1);
    color: var(--color2);
  }
 
  .link-url:hover{
    color: var(--color1);
  }
  .company-address{
    cursor: pointer;
  }
  footer{
    background-color: var(--color1);
    color: var(--color2);
  }
  footer ul li a{
    font-size: 14px;
  }
  input{
    outline: none;
  }
  input::placeholder{
    font-size: 12px;
  }
  footer hr{
    border-top: 2px solid #fff;
    background-color: var(--color2);
  }
  .swiper-button-prev, 
  .swiper-button-next{
    background-color: #fff;
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
  }
  .swiper-button-prev:after, 
   .swiper-button-next:after{
    color: gray;
   line-height: unset !important;
   font-size: 19px !important;
   font-weight: 700 !important;
  }
  .medium-text{
    font-size: 17px;
    text-align: justify;
  }
  
  .product-banner img{
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
  .desc-text{
    font-size: 12px;
    color: gray;
  }
  .product-card{
    height: 200px;
    padding: 0 20px;
  }
  .product-card img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .divider{
    height: 2px;
    background-color: #000;
  }
 .product-detail .swiper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  
 .product-detail .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  
 .product-detail .mySwiper2 {
    height: 75%;
    width: 100%;
  }
  
 .product-detail .mySwiper {
    height: 16%;
    box-sizing: border-box;
    padding: 10px 0;
    
  }
  
.product-detail  .mySwiper .swiper-slide {
    width: 25%;
    height: 100%;
    opacity: 0.4;
  }
  
 .product-detail .mySwiper .swiper-slide-thumb-active {
    opacity: 1;
  }
  
 .product-detail .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
   
    border-radius: 8px;
  }
  
  .feature-desc li::before{
    content: "";
    position: absolute;
    left: -2%;
    top: 50%;
    width: 6px;
    height: 6px;
    background-color: var(--color1);
    border-radius: 50%;
    transform:  translateY(-50%);
  }
  .loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after {
    position: absolute;
    top: 0;
    content: '';
  }
  
  .jimu-primary-loading:before {
    left: -19.992px;
  }
  
  .jimu-primary-loading:after {
    left: 19.992px;
    -webkit-animation-delay: 0.32s !important;
    animation-delay: 0.32s !important;
  }
  
  .jimu-primary-loading:before,
  .jimu-primary-loading:after,
  .jimu-primary-loading {
    background: var(--color1);
    -webkit-animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    animation: loading-keys-app-loading 0.8s infinite ease-in-out;
    width: 13.6px;
    height: 32px;
  }
  
  .jimu-primary-loading {
    text-indent: -9999em;
    margin: auto;
    position: absolute;
    right: calc(50% - 6.8px);
    top: calc(50% - 16px);
    -webkit-animation-delay: 0.16s !important;
    animation-delay: 0.16s !important;
  }
  
  @-webkit-keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 var(--color1);
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px var(--color1);
      height: 40px;
    }
  }
  
  @keyframes loading-keys-app-loading {
  
    0%,
    80%,
    100% {
      opacity: .75;
      box-shadow: 0 0 var(--color1);
      height: 32px;
    }
  
    40% {
      opacity: 1;
      box-shadow: 0 -8px var(--color1);
      height: 40px;
    }
  }
  .sub-category li{
    background-color: rgb(230, 230, 230);
  }
  .sub-category li:first-of-type{
    font-weight: 500;
  }
  .dropdown-item-2 li{
    cursor: pointer;
  }
.dropdown-item-2 li:hover{
  background-color: var(--color1);
  border-radius: 4px;
  color: var(--color2);
}
  .activeproduct{
    background-color: var(--color1);
    border-radius: 4px;
  }
  .activeproduct a{
    color: var(--color2);
  }
  
  .catalogue-btn{
    background-color: var(--color1);
    padding: 9px 0;
    color: var(--color2) !important;
  }
  
 .service-box img{
  width: 100%;
 }
 .contact-info{
  display: none;
 }
 .modal-box{
  background-color: rgba(0, 0, 0, 0.8);
  /* height: 100vw; */
 }
 .pri-head{
  color: var(--color1);
 }
 .sub-category li:hover a{
  color: var(--color1);
 }
 ::-webkit-scrollbar{ 
  width: 8px;
  height: 5px; 
}
::-webkit-scrollbar-thumb{
  background-color: var(--color1);
  border-radius: 18px;
}
::-webkit-scrollbar-track{
 box-shadow: inset 0 0 6px var(--color1);
  border-radius: 18px;
}
nav hr{
  margin-top: 1rem;
  margin-bottom: 0;
}
.sidebar hr{
  margin-top: 1rem;
  margin-bottom: 0;
}
.company p{
  font-size: 15px;
}
.career{
  background-color: var(--color1);
}
.description-box img{
  width: 100% !important;
  height: 100% !important;
  object-fit: contain;
}
.mySwiper .swiper-slide {
  width: 25%;
  height: 100%;
 
  cursor: pointer;
}

.mySwiper .swiper-slide-thumb-active {
  opacity: 1;
}

.company a{
  color: unset;
}
.ser-link{
  display: flex;
  height: 40px;
  align-items: center;
}
.ser-link.active{
  border-left: 10px solid var(--color1);
  color: var(--color1);
}
.pro-title:hover{
  color: var(--color1);
}
.find-btn{
  border: none;
  transition: all ease-in-out 0.1s;
}
.find-btn:hover {
  color: var(--color1);
  border-bottom: 2px solid var(--color1);
}
.card-body:hover .card-tilte{
  color: var(--color1);
}
.search-more{
  background-color: var(--color1);
}
.carousel .control-prev.control-arrow:before{
  border-right: 10px solid #000 !important;
}
.carousel .control-next.control-arrow:before{
  border-left: 10px solid #000 !important;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow{
  opacity: 1 !important;
}
.contact-text:hover,
.company-address:hover h5,
.company-address:hover a{
  color: var(--color1);
}
.product-slider a{
  color: unset;
}
.product-slider .card-body:hover h5{
  color: var(--color1);
}
/* Styling for the Google Translate dropdown box */
.goog-te-gadget{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.goog-te-combo {
  font-size: 14px; 
  color: #878787; 
  border: 1px solid #ccc; 
  border-radius: 4px; 
  padding: 8px 5px; 
  background-color: #fff; 
  width: 99%;
}
select{
  outline: none;
}
/* Styling for the dropdown arrow */
.goog-te-combo-arrow {
  width: 0;
  height: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 6px solid #666;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 5px; 
}
svg {
  display: inline-block;
  vertical-align: middle;
}

path {
  fill: #2a354d;
}
.animated-border{
  
  color: #066d6d;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  font-weight: 700;
  
 
}
.ani-text,
.career-title{
  color: #066d6d;
}
.google-map iframe{
  margin-top: -110px;
}
.product-category-card{
    overflow: hidden;
   
}
.product-category-card:hover img{
  transform: scale(1.3);
  transition: transform 0.7s ease;
}
.product_category:hover .pro-title{
  color: #149A9B;
}
.sustainable-banner{
  height: 500px;
  background: url(../asset/3d-solar-pannels-project-energy-savin.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sustainable-banner h1{
  letter-spacing: 2px;
  line-height: 55px;
  backdrop-filter: blur(2px);
}
.solution-banner{
  height: 400px;
  background: url(../asset/867534.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.layer-banner{
  height: 400px;
  background: url(../asset/Layer\ 6.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.network-banner{
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;
}
.network-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../asset/infographic\ image\ \(1\).svg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  transform-origin: center center;
  
}
.layer-banner p{
  line-height: 40px;
}
.growth-banner{
  height: 450px;
  background: url(../asset/womans-hands-gloves-planting-young-plant.jpg);
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.sustainable-border {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  width: 0%;
  background-color: #91eb42; 
  animation: borderAnimation 3s linear infinite;
}
.sustainable-border-2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  width: 0%;
  background-color: #91eb42; 
  animation: borderAnimation 3s linear infinite;
}

@keyframes borderAnimation {
  0% {
    width: 0%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}
.job-search input::placeholder{
  font-size: 20px;
  color: #c0bfbf;
  font-weight: 300;
}
.video-container iframe{
  width: 100% !important;
  height: 200px !important;
}
.video-container-2 iframe{
  width: 100% !important;
  height: 400px !important;
}
.page-not-found h1{
  font-size: 120px;
  color: var(--color1);
}
.dot-spinner {
  --uib-size: 2rem;
  --uib-speed: .9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  
}
.dot-spinner2{
  --uib-color: #fff;
}
.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: '';
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
.form {
  display: flex;
  flex-direction: column;
  align-self: center;
  font-family: inherit;
  gap: 10px;
  padding-inline: 2em;
  padding-bottom: 0.4em;
  background-color: #ffffff;
  /* //background-color: #0a192f; */
  border-radius: 5px;
  border: 1.5px solid rgb(220, 219, 219);
}

.form-heading {
  text-align: center;
  margin: 1em 2em;
  color: var(--color1);
  font-size: 1.2em;
  background-color: transparent;
  align-self: center;
}

.form-field {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
  border-radius: 10px;
  padding: 0.6em;
  border: none;
  outline: none;
  color: #000;
  background-color: #fff;
  
  /* box-shadow: inset 2px 5px 10px rgb(211, 211, 211); */
}
.form-card2 label{
  color: var(--color1);
}
.input-field {
  background: none;
  border: none;
  outline: none;
  width: 100%;
  color: #000;
  padding-inline: 1em;
}

.sendMessage-btn {
  cursor: pointer;
  margin-bottom: 2em;
  padding: .4em .8em;
  width: 140px;
  border: none;
  outline: none;
  background-color: var(--color1);
  color: #fff;
  font-weight: bold;
  border: 1.5px solid var(--color1);
  transition: all ease-in-out 0.3s;

}
.support-card{
  background-color: #ddf1f1;
}
.support_divider{
  background-color: var(--color1);
}
@media screen and (min-width: 780px) {
  .solution-banner p,
  .growth-banner p{
    width: 60%;
  }
}
@media screen and (max-width: 768px) {
  .network-banner::before {
   display: none;
 
  }
  .network-banner{
    height: 180px;
  }
  .banner-content{
    bottom: 0%;
   
}
.banner-content h1:last-of-type{
  font-size: 40px;
}
    .banner-content{
width: 100%;
padding: 0 10px;
    }
    .banner,
    .banner .lazy-load-image-background{
      height: 340px;
    }
    
    .clearfix img{
      width: 100%;
    }
    h1.title-text{
      font-size: 1.2rem;
    }
    .story-cover-img img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
}
@media screen and (max-width: 900px) {
  .dropdown-item{
    left: 0%;
    width: 100%;
  }
  .dropdown-item-2{
    right: 0;
    top: 100%;
  }
  .sidebar{
    width: 350px;
  }
  }